import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'CLIA Compliance Checklist For DPC';
    const desc = `Follow these steps to stay CLIA compliant in your direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="clia">
        <Head title={title} description={desc} url="/todo/clia/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
